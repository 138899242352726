
[v-cloak]
  display: none

#app
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #2c3e50

body,html
  margin: 0
  font-size: 15px
  color: #535353
  background-color: #ecf1f5

